<template>
  <div class="bg-grey">
    <ApplicantHeader />
    <div class="container-fluid profile-user px-1 px-md-3 pt-4 pb-5">
      <div class="col-md-12 box pb-5 mb-5">
        <div class="profile-flex border-bottom py-3">
          <h4 class="heading">Profile</h4>
          <button v-if="profile && profile.user_job_cv_name" class="btn btn-primary btn-crud"
            @click="$router.push({ name: 'Applicant-Onboarding-1' })"><img src="@/assets/app-icons/edit.svg"
              width="15px" /> View and Edit CV</button>
        </div>
        <div class="row pt-4">
          <div class="col-md-4 col-lg-4 col-xl-3 col-10 pt-4">
            <h6 class="profile-label">CV</h6>
            <!-- <div>
              <img
                src="@/assets/linkedin.png"
                alt="linkedin-icon"
                width="80%"
              />
            </div> -->
            <div class="resume-box mt-3">
              <div class="resume-doc" v-b-tooltip.hover :title="profile.user_job_cv_name">
                <img src="@/assets/app-icons/pdf.svg" class="img-fluid mr-3" alt="pdf-icon" width="20px" />
                <div v-if="profile.user_job_cv_path">
                  <a :href="profile.user_job_cv_path" target="_blank">{{ profile.user_job_cv_name }}</a>
                </div>
                <div v-if="!profile.user_job_cv_path">
                  <span> Resume not uploaded</span>
                </div>
              </div>

              <div class="resume-action" style="cursor: pointer" @click="onDeleteResume">
                <img src="@/assets/app-icons/delete.svg" class="img-fluid" alt="delete-icon" width="20px" />
              </div>
            </div>
            <div class="d-flex pt-4">
              <!-- <button
                class="btn btn-primary reupload-btn mr-3"
                @click="onReloadResume"
              >
                RELOAD RESUME
              </button> -->
              <div class="custom-upload" v-if="!profile.user_job_cv_path">
                <label for="resumeInput" class="btn btn-primary reupload-btn">
                  <img src="@/assets/app-icons/upload.svg" class="img-fluid mr-1" alt="upload-icon" width="20px" />

                  {{ cvSelected ? "File Selected" : "UPLOAD CV" }}
                </label>
                <input onclick="this.value = null" id="resumeInput" type="file" @change="onResumeUpload" />
              </div>
            </div>
            <!-- Reload ur cv -->

            <!-- <label for="resumeReload" class="btn reupload-btn">
                <img
                  src="@/assets/app-icons/upload.svg"
                  class="img-fluid mr-3"
                  alt="upload-icon"
                  width="20px"
                />

                {{ cvSelected ? "File Selected" : "UPLOAD CV" }}
              </label> -->

            <!-- Reload ur cv -->
            <!-- <input type="file" id="resumeInput" @change="onResumeUpload" /> -->
          </div>
          <div class="col-md-2 col-lg-2 col-8 pt-4">
            <!-- <div v-if="profile.user_job_profile_image">
              <h6 class="f-light pb-3">Profile Photo</h6>
              <img
                :src="profile.user_job_profile_image"
                class="img-fluid rounded-lg"
                width="100"
              />
            </div> -->
            <div>
              <h6 class="profile-label">Profile Photo</h6>
              <div class="position-relative profile-photo">
                <img v-if="$store.getters.getProfileImage" :src="this.$store.getters.getProfileImage" class="rounded"
                  width="100" />
                <img v-if="!$store.getters.getProfileImage" src="../../assets/unknown.jpg" alt="" class="rounded"
                  width="100" />
              </div>
              <div class="app-profile-overlay">
                <div @click="onDeleteImage" style="curser: pointer">
                  <img src="@/assets/app-icons/delete-orange.svg" width="60px" style="cursor: pointer" />
                </div>
                <div>
                  <label for="file-upload" class="mb-0">
                    <img src="@/assets/app-icons/re-upload.svg" width="60px" style="cursor: pointer" />
                  </label>
                  <input id="file-upload" type="file" @input="onUploadImage" style="display: none" />
                </div>
              </div>
            </div>
          </div>
          <div class="offset-xl-4 offset-lg-1 offset-md-1 col-md-5 col-lg-5 col-xl-3 col-12">
            <div class="profile-box mt-4 mt-md-0">
              <div class="profile-box-header">
                <h4 class="profile-box-title">
                  Improve <br />
                  your profile
                </h4>
                <div>
                  <radial-progress-bar :diameter="90" :stroke-width="8" :inner-stroke-width="10" inner-stroke-color="#fff"
                    start-color="#6244e5" stop-color="#6244e5" :completed-steps="completedSteps"
                    :total-steps="totalSteps">
                    <p class="f-primary mb-0 font-weight-bold">{{ completedSteps }}%</p>
                  </radial-progress-bar>
                </div>
              </div>
              <div class="profile-box-content">
                <p>Add the following data to your profile to reach 100% and maximise your chance of getting recognised by
                  employers</p>

                <div class="profile-box-step">
                  <p :class="profile.user_job_cv_path ? 'f-light strike-through' : 'fpn-b text-dark'">Add CV</p>
                  <p :class="profile.user_job_profile_image ? 'f-light strike-through' : 'fpn-b text-dark'">Add Profile
                    Photo</p>
                  <p :class="skills.length > 0 ? 'f-light strike-through' : 'fpn-b text-dark'">Add Skills</p>

                  <p
                    :class="profile.facebook || profile.twitter || profile.instagram || profile.linked_in ? 'f-light strike-through' : 'fpn-b text-dark'">
                    Add Social Links</p>
                  <!-- subs && subs.subs_pkg_no_of_videos > 0 -->
                  <p :class="videos && videos.length > 0 ? 'f-light strike-through' : 'fpn-b text-dark'">Add video
                    profiles</p>
                </div>
              </div>
            </div>
            <!--End Profile Box-->
          </div>
        </div>
        <!--End Row-->
        <div class="row">
          <div class="col-md-10 col-lg-12 col-xl-9 col-12 profile-details mt-4 mt-xl-0">
            <div>
              <h6>Full Name</h6>
              <h5>{{ profile.user_username }}</h5>
            </div>
            <div>
              <h6>Job Title</h6>
              <h5>{{ profile.user_job_title }}</h5>
            </div>
            <div>
              <h6>Age</h6>
              <h5>{{ profile.user_age }}</h5>
            </div>
            <div>
              <h6>E-Mail</h6>
              <h5>
                {{ profile.user_email }}
              </h5>
            </div>
            <div>
              <h6>Industry</h6>
              <h5>{{ profile.industry_name }}</h5>
            </div>
            <div>
              <h6>Years of Experience</h6>
              <h5>{{ profile.user_job_experience }}</h5>
            </div>
            <div>
              <h6>Country of residence</h6>
              <h5>
                {{ profile.country_name }}
              </h5>
            </div>
            <div>
              <h6>Nationality</h6>
              <h5>
                {{ profile.user_job_nationality }}
              </h5>
            </div>
            <div>
              <h6>Current Organisation</h6>
              <h5>
                {{ profile.user_job_current_org }}
              </h5>
            </div>
            <div>
              <h6>Preferred Job</h6>
              <h5>{{ profile.user_job_preferred }}</h5>
            </div>
            <div class="intro-div">
              <h6>Introduction</h6>
              <h5>
                {{ profile.user_job_introduction }}
              </h5>
            </div>
          </div>

          <div class="col-md-6 col-12 flex-between">
            <div class="w-75 skills-labels">
              <h6 class="f-light">Skills</h6>
              <div v-if="profile.skills && profile.skills.length > 0">
                <label style="color:black;" v-for="(skill, index) in profile.skills" :key="index" class="label-grey">{{
                  skill }}</label>
              </div>
              <div v-else>No skills added</div>
            </div>
            <div>
              <h6 class="f-light">Social Links</h6>
              <div class="d-flex justify-content-between">
                <h6 class="fs-25 social-icon-box" v-if="profile && profile.facebook" @click="openUrl(profile.facebook)">
                  <i class="fa fa-facebook text-white" aria-hidden="true"></i>
                </h6>
                <h6 class="fs-25 social-icon-box" v-if="profile && profile.linked_in" @click="openUrl(profile.linked_in)">
                  <i class="fa fa-linkedin text-white"></i>
                </h6>
                <h6 class="fs-25 social-icon-box" v-if="profile && profile.instagram" @click="openUrl(profile.instagram)">
                  <i class="fa fa-instagram text-white"></i>
                </h6>
                <h6 class="fs-25 social-icon-box" v-if="profile && profile.twitter" @click="openUrl(profile.twitter)">
                  <i class="fa fa-twitter text-white"></i>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        class="col-md-12"
        v-if="
          (subs && subs.subs_pkg_no_of_videos == 0) ||
            (subs && !subs.subs_pkg_id)
        "
      >
        <div class="profile-flex border-bottom">
          <h1>Please upgrade your subscription to upload profile video</h1>
        </div>
      </div> -->
      <div class="col-md-12 box pb-5">
        <!-- v-show="subs && subs.subs_pkg_id && subs.subs_pkg_no_of_videos != 0" -->
        <!-- <div
          class="profile-flex border-bottom"
          v-if="subs && subs.subs_pkg_id && subs.subs_pkg_no_of_videos == 0"
        >
          <h1>Please upgrade your subscription to upload profile video</h1>
        </div> -->
        <div class="profile-flex border-bottom">
          <h4 class="heading">My Videos</h4>
          <ul class="slides-arrow--black d-flex mb-0">
            <li class="prev"><i class="fa fa-angle-left"></i></li>
            <li class="next"><i class="fa fa-angle-right"></i></li>
          </ul>
        </div>
        <!-- <div class="text-center" v-if="loading">
          <RadialProgressBar
            v-show="showRadial"
            :diameter="90"
            :stroke-width="8"
            :inner-stroke-width="10"
            inner-stroke-color="#fff"
            start-color="#6244e5"
            stop-color="#6244e5"
            :completed-steps="videoUploaded"
            :total-steps="totalSteps"
          >
            {{ videoUploaded }}%
          </RadialProgressBar>
          <b-spinner
            v-if="loading"
            label="Loading..."
            style="width: 5rem; height: 5rem"
          >
          </b-spinner>
          <span>Uploading...</span>
        </div> -->
        <b-modal id="myModel" hide-header hide-footer>
          <div class="text-center">
            <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
            <h2>Uploading...</h2>
          </div>
        </b-modal>
        <b-modal ref="resumeLoading" hide-header hide-footer>
          <div class="text-center">
            <b-spinner label="Loading..." style="width: 8rem; height: 8rem"> </b-spinner>
            <h2>Please wait while your details are being auto filled...</h2>
          </div>
        </b-modal>

        <!-- Vidio recorder -->
        <!-- <div>
          <video id="myVideo" playsinline class="video-js vjs-default-skin">
            <p class="vjs-no-js">
              To view this video please enable JavaScript, or consider upgrading
              to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
              >
                supports HTML5 video.
              </a>
            </p>
          </video>
          <br />
          <button
            type="button"
            class="btn btn-info"
            @click.prevent="startRecording()"
            v-bind:disabled="isStartRecording"
            id="btnStart"
          >
            Start Recording
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click.prevent="submitVideo()"
            v-bind:disabled="isSaveDisabled"
            id="btnSave"
          >
            {{ submitText }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click.prevent="retakeVideo()"
            v-bind:disabled="isRetakeDisabled"
            id="btnRetake"
          >
            Retake
          </button>
        </div> -->
        <b-modal ref="videoRecorder" size="xl" hide-header hide-footer>
          <div class="text-center">
            <h2>Record Your Video</h2>
            <!-- <video ref="myVideo" playsinline class="video-js vjs-default-skin">
              <p class="vjs-no-js">
                To view this video please enable JavaScript, or consider
                upgrading to a web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  target="_blank"
                >
                  supports HTML5 video.
                </a>
              </p>
            </video>
            <br />
            <button
              type="button"
              class="btn btn-info"
              @click.prevent="startRecording()"
              v-bind:disabled="isStartRecording"
              id="btnStart"
            >
              Start Recording
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click.prevent="submitVideo()"
              v-bind:disabled="isSaveDisabled"
              id="btnSave"
            >
              {{ submitText }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="retakeVideo()"
              v-bind:disabled="isRetakeDisabled"
              id="btnRetake"
            >
              Retake
            </button> -->
          </div>
        </b-modal>
        <!-- video recorder -->
        <slick ref="slick" :options="videoSlider" v-if="videos.length > 0">
          <div v-for="(data, index) in videos" :key="index">
            <div class="p-3 position-relative" @click="onClick(data)">
              <!-- <a @click="onSelectVideo(index)" style="cursor: pointer"> -->
              <div style="cursor: pointer w-100">
                <img :id="data.user_job_video_path" :name="data.user_job_video_path"
                  :src="`https://videodelivery.net/${data.user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=300`"
                  style="border: none" height="300" width="100%" />
              </div>
              <span>{{ data.user_job_video_title }}</span>
              <img src="@/assets/app-icons/orange-video.svg" class="img-center video-play-img" width="50px" />
              <!-- </a> -->
              <!-- <CloudflareVideoPlayer
                :video-id="data.user_job_video_path"
                :autoplay="false"
              /> -->
            </div>
          </div>
        </slick>
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="video-section">
              <div class="thumbnail">
                <div v-show="recordingOn">
                  <video id="myVideo" playsinline class="video-js vjs-default-skin">
                    <p class="vjs-no-js">
                      To view this video please enable JavaScript, or consider upgrading to a web browser that
                      <a href="https://videojs.com/html5-video-support/" target="_blank"> supports HTML5 video. </a>
                    </p>
                  </video>
                  <br />
                  <div class="d-flex py-2">
                    <button type="button" class="btn btn-info mx-2" @click.prevent="startRecording()"
                      v-bind:disabled="isStartRecording" id="btnStart">Start Recording</button>
                    <button type="button" class="btn btn-danger mr-2" @click.prevent="finishRecording()">Stop
                      Recording</button>
                    <button type="button" class="btn btn-success mr-2" @click.prevent="submitVideo()"
                      v-bind:disabled="isSaveDisabled" id="btnSave">
                      {{ submitText }}
                    </button>
                    <button type="button" class="btn btn-primary" @click.prevent="retakeVideo()"
                      v-bind:disabled="isRetakeDisabled" id="btnRetake">Retake</button>
                  </div>
                </div>
                <div v-if="!recordingOn && videos.length > 0">
                  <iframe v-if="!recordingOn && videos.length > 0"
                    :src="`https://iframe.videodelivery.net/${selectedVideo ? selectedVideo.user_job_video_path : videos[0].user_job_video_path}/thumbnails/thumbnail.jpg?time=1s&height=500`"
                    style="border: none" preload="auto" height="300" width="100%"
                    allow="accelerometer; gyroscope;encrypted-media; picture-in-picture;" allowfullscreen="true"
                    id="stream-player"></iframe>

                  <!-- <vue-core-video-player
                    :core="HLSCore"
                    :autoplay="false"
                    @play="playFunc"
                    @pause="pauseFunc"
                    :src="
                      `https://videodelivery.net/${
                        selectedVideo
                          ? selectedVideo.user_job_video_path
                          : videos[0].user_job_video_path
                      }/manifest/video.m3u8`
                    "
                  ></vue-core-video-player> -->

                  <!-- <video
                    v-if="!recordingOn && videos.length > 0"
                    controls
                    @pause="onClickVideo"
                    @ended="onClickVideo"
                    :src="
                      `https://iframe.videodelivery.net/${
                        selectedVideo
                          ? selectedVideo.user_job_video_path
                          : videos[0].user_job_video_path
                      }/thumbnails/thumbnail.jpg?time=1s&height=500`
                    "
                    :poster="
                      `https://videodelivery.net/${
                        selectedVideo ? selectedVideo.user_job_video_path : null
                      }/thumbnails/thumbnail.jpg?time=1s&height=900`
                    "
                    style="border: none"
                    height="300"
                    width="100%"
                    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                    allowfullscreen="true"
                  ></video> -->
                </div>
              </div>
              <div class="action-icons" v-if="!recordingOn && !playVideo">
                <div v-if="videos.length > 0" @click="() => {
                    if (videos.length > 0) {
                      playVideo = true;
                    }
                  }
                  ">
                  <figure>
                    <img src="@/assets/app-icons/icon-play.svg" />
                  </figure>
                  <p>PLAY</p>
                </div>
                <div v-if="videos.length > 0" @click="onDeleteVideo">
                  <figure>
                    <img src="@/assets/app-icons/icon-delete.svg" />
                  </figure>
                  <p>DELETE</p>
                </div>
                <div v-if="videos.length > 0" @click="getUploadUrl">
                  <figure>
                    <img src="@/assets/app-icons/icon-record.svg" />
                  </figure>
                  <p>RE-RECORD</p>
                </div>

                <button @click="getUploadUrl" class="btn btn-primary" v-if="!videos.length">ADD A VIDEO PROFILE</button>
                <!-- <div>
                  <figure>
                    <img src="@/assets/app-icons/icon-upload.svg" />
                  </figure>
                  <p>RE-UPLOAD</p>
                </div> -->
              </div>

              <!-- <button class="btn btn-delete" @click="onDeleteVideo">
                  Delete Video
                </button> -->
              <!-- <div
                class="thumbnail-heading"
                v-if="subs && subs.subs_pkg_no_of_videos > 0"
              >
                <button class="btn btn-primary" @click="addVideo">
                  Add Video
                </button>
              </div> -->
              <!-- <h5 class="fpn-b">Video Profile #1</h5>
                <h6 class="f-light fs-14">2m 30s</h6> -->
            </div>
          </div>
          <div class="col-md-6 col-12">
            <ValidationObserver ref="videoInput" tag="div">
              <b-form class="customized-form mt-4">
                <ValidationProvider name="video_title" rules="required" v-slot="{ errors, classes }">
                  <!-- :disabled="selectedVideo && editVideoDetails ? false : true" -->
                  <b-form-input :class="classes" placeholder="Video Title" v-model="video_title"> </b-form-input>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
                <ValidationProvider name="video-desc" rules="required|max:150" v-slot="{ errors, classes }">
                  <!-- :disabled="selectedVideo && editVideoDetails ? false : true" -->
                  <b-form-textarea size="lg" :class="classes" placeholder="Video Description (Max 100 words)" rows="10"
                    max-rows="15" v-model="video_description"> </b-form-textarea>
                  <div class="error-msg" :class="classes">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </b-form>
            </ValidationObserver>
            <!-- <div class="drag-drop text-center">
              <h6 class="text">Upload your files</h6>

              <video ref="video" style="display: none"></video>
              <canvas ref="canvas" style="width: 100px"></canvas>

              <div class="custom-upload">
                <label for="video" class="btn btn-primary reupload-btn">
                  <img
                    src="@/assets/app-icons/upload.svg"
                    class="img-fluid mr-1"
                    alt="upload-icon"
                    width="20px"
                  />

                  {{ fileSelected != "" ? fileSelected : "Choose File" }}
                </label>
                <input
                  type="file"
                  accept="video/*"
                  id="video"
                  @change="onFileChange"
                />
                <br /><br />
              </div>
            </div>
            <div class="text-center">
              <button
                class="btn btn-primary btn-video-upload mx-auto"
                @click="getUploadUrl"
              >
                Upload Video
              </button>
            </div> -->
          </div>
        </div>
        <div class="thumbnail-heading mt-4" v-if="subs && subs.subs_pkg_no_of_videos > 0">
          <button v-if="videos.length > 0" class="btn btn-primary" @click="addVideo">Add Video</button>
        </div>
      </div>
      <div class="col-md-12 box mt-5">
        <div class="profile-flex border-bottom mb-4">
          <h4 class="heading">Suggested Interview Questions</h4>
        </div>

        <p>Answer these commonly asked questions and increase your overall score while applying for a job</p>
        <button class="btn btn-primary mb-4" @click="$router.push({ name: 'Applicant-Suggested-Ques' })">Start
          Answering</button>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicantProfileService from "@/apiServices/ApplicantProfileService";
import GetVideoUploadUrl from "@/apiServices/GetVideoUploadUrl";
import RadialProgressBar from "vue-radial-progress";
import CloudflareVideoPlayer from "vue-cloudflare-video-player";
import ApplicantHeader from "@/views/Applicant-Profile-Screens/Applicant.vue";
import HLSCore from "@core-player/playcore-hls";
import axios from "axios";
import moment from "moment";

import "video.js/dist/video-js.css";
import "videojs-record/dist/css/videojs.record.css";
import videojs from "video.js";
import "webrtc-adapter";
import RecordRTC from "recordrtc";
import Record from "videojs-record/dist/videojs.record.js";
import FFmpegjsEngine from "videojs-record/dist/plugins/videojs.record.ffmpegjs.js";

export default {
  components: {
    RadialProgressBar,
    CloudflareVideoPlayer,
    ApplicantHeader,
  },
  data() {
    return {
      HLSCore,
      iframe: null,
      pause: null,
      player: "",
      retake: 0,
      archived: null,
      uploadImg: false,
      video_status: "update",
      isSaveDisabled: true,
      isStartRecording: false,
      isRetakeDisabled: true,
      submitText: "Submit",
      options: {
        controls: true,
        bigPlayButton: false,
        controlBar: {
          deviceButton: false,
          recordToggle: false,
          pipToggle: false,
        },
        width: 500,
        height: 300,
        fluid: true,
        plugins: {
          record: {
            pip: false,
            audio: true,
            video: true,
            maxLength: 60,
            debug: true,
          },
        },
      },
      ///////////////////////////////////////////
      recordingOn: false,
      playVideo: false,
      completedSteps: 0,
      totalSteps: 100,
      cvName: null,
      showRadial: false,
      url: "",
      fileSelected: "",
      previewImage: null,
      parser_id: null,
      editVideoDetails: false,
      subs: null,
      job_title: null,
      exp: [],
      edu: [],
      user_id: this.$cookies.get("user_id"),
      current_org: null,
      cv: null,
      profile: {},
      education: [],
      videoInput: null,
      cvSelected: false,
      videoUploaded: 0,
      exp: [],
      videos: [{}],
      video_description: null,
      video_title: null,
      loading: false,
      selectedVideo: null,
      skills: [],
      videoSlider: {
        arrows: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        prevArrow: ".prev",
        nextArrow: ".next",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    // this.getResumeParserData();

    this.getAllVideos();
    this.getSubs();
    this.getProfile();
  },
  mounted: function () {
    this.iframe = Stream(document.getElementById("stream-player"));
    console.log("this.iframe :>> ", this.iframe);

    this.iframe.addEventListener("pause", () => {
      console.log("pause!");
      this.playVideo = false;
      this.recordingOn = false;
    });

    this.iframe.addEventListener("play", () => {
      console.log("playing!");
      this.playVideo = true;
      this.recordingOn = false;
    });
    this.iframe.play().catch(() => {
      console.log("playback failed, muting to try again");
      this.iframe.muted = true;
      this.iframe.play();
    });

    this.$store.commit("RESET_EMAIL");
    this.$store.commit("RESET_NAME");
  },
  methods: {
    showModal() {
      this.$refs["resumeLoading"].show();
    },
    hideModal() {
      console.log("inside hide :>> ");
      this.$refs["resumeLoading"].hide();
    },
    showVideoRecorder() {
      this.$refs["videoRecorder"].show();
    },
    hideVideoRecorder() {
      this.$refs["videoRecorder"].hide();
    },
    addVideo() {
      if (!this.subs || (this.subs && this.subs.subs_pkg_no_of_videos == 0)) {
        this.$toasted.show("You dont have any plan", {
          duration: 6000,
          icon: "clear",
        });
        return;
      } else if (this.subs.subs_pkg_no_of_videos <= this.videos.length) {
        this.$toasted.show("You have reached max limit", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      this.video_status = "add";
      this.video_title = null;
      this.video_description = null;
      this.getUploadUrl();
    },
    playFunc(e) {
      console.log(e);
      this.recordingOn = false;
      this.playVideo = true;
    },
    pauseFunc(e) {
      console.log(e);
      this.recordingOn = false;
      this.playVideo = false;
    },
    configPlayer() {
      if (this.player === "") {
        this.player = videojs("myVideo", this.options, () => {
          // print version information at startup
          var msg = "Using video.js " + videojs.VERSION + " with videojs-record " + videojs.getPluginVersion("record") + " and recordrtc " + RecordRTC.version;
          videojs.log(msg);
        });
        // error handling
        this.player.on("deviceReady", () => {
          this.player.record().start();
          console.log("device ready:");
        });
        this.player.on("deviceError", () => {
          console.log("device error:", this.player.deviceErrorCode);
        });
        this.player.on("error", (element, error) => {
          console.error(error);
        });
        // user clicked the record button and started recording
        this.player.on("startRecord", () => {
          console.log("started recording!");
        });
        // user completed recording and stream is available
        this.player.on("finishRecord", () => {
          this.isSaveDisabled = false;
          if (this.retake == 0) {
            this.isRetakeDisabled = false;
          }
          // the blob object contains the recorded data that
          // can be downloaded by the user, stored on server etc.
          console.log("finished recording: ", this.player.recordedData);
        });
      } else {
        console.log("player not initialise", this.player);
      }
    },
    // onClickVideo() {
    //   console.log("Video pause or end");
    //   this.recordingOn = false;
    //   this.playVideo = false;
    // },
    async getUploadUrl() {
      //  this.showVideoRecorder();
      console.log("this.subs :>> ", !this.subs);
      if (!this.subs) {
        this.$toasted.show("You dont have plan to upload a video!", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
      if ((this.subs && this.subs.subs_pkg_no_of_videos == 0) || (this.subs && !this.subs.subs_pkg_id)) {
        this.$toasted.show("You dont have plan to upload a video!", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }

      GetVideoUploadUrl.GetVideoUploadUrl()
        .then((res) => {
          this.editVideoDetails = true;
          console.log("get upload url successfully!");
          this.url = res.data.data.result.uploadURL;
          this.recordingOn = true;
          this.isStartRecording = false;
          this.configPlayer();
          this.player.record().reset();
          //  this.dropzoneOptions.url = this.url;

          //   this.onUpload();
        })
        .catch((err) => {

          // this.player.dispose();
          this.recordingOn = false;
          this.$toasted.show(
            `Internal Server Error.
          Video May not upload.
          please Try Again`,
            {
              duration: 5000,
              icon: "clear",
            }
          );
        });
    },

    openUrl(url) {
      if (!url.match(/^https?:\/\//i)) {
        url = "http://" + url;
      }
      return window.open(url, "_blank");
    },
    onReloadResume() {
      const parser_id = this.parser_id;
      ApplicantProfileService.getUploadCv({ parser_id })
        .then((res) => {

          let parserData = res.data.data;
          this.setDataFromParser(parserData);
        })
        .catch((err) => {

          this.$toasted.show("please Upload Your resume", {
            duration: 6000,
            icon: "clear",
          });
        });
    },
    onDeleteResume() {
      ApplicantProfileService.deleteMainCv()
        .then((res) => {

          this.getProfile();
          this.$toasted.show("Resume deleted", {
            duration: 6000,
            icon: "check",
          });
        })
        .catch((err) => {

          this.$toasted.show("Error while resume delete", {
            duration: 6000,
            icon: "clear",
          });
        });
    },
    onResumeUpload(e) {
      let file = e.target.files[0];
      console.log("file :>> ", file);

      if ((file && file.type == "application/pdf") || (file && file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
        if (file.size >= 1000000) {
          this.$toasted.show("pdf file should be less then 1 mb", {
            duration: 6000,
            icon: "clear",
          });
          return;
        }
        this.showModal();
        const user_id = this.$cookies.get("user_id");

        //const data = file.getAsBinary();
        const form = new FormData();
        form.append("resume", e.target.files[0]);
        form.append("user_id", user_id);

        ApplicantProfileService.uploadCv(form)
          .then((res) => {
            // let { identifier } = res.data.data;
            // this.parser_id = identifier;
            this.cvSelected = true;
            // this.getResumeParserData();
            console.log("res.data.data ------------:::>> ", res.data.data);
            let parserData = res.data.data;
            this.parser_id = parserData.parser.meta.identifier;
            this.setDataFromParser(parserData);

            this.hideModal();
          })
          .catch((error) => {

            this.hideModal();
            this.$toasted.show("Error while uploading Resume", {
              duration: 6000,
              icon: "clear",
            });
          });
      } else {
        this.$toasted.show("Select .pdf/.docx file", {
          duration: 6000,
          icon: "clear",
        });
        return;
      }
    },

    async setDataFromParser(getParser) {
      console.log("getParser :>> ", getParser);
      let parser = getParser.parser.data;
      parser.cv = getParser.cv;
      console.log("parser :>> ", parser);
      // this.profile.user_username = parser.name?.raw;
      // this.profile.user_job_title =
      //   parser.sections[0] && parser.sections[0].text
      //     ? parser.sections[0]?.text.split("\n")[1]
      //     : null;
      // this.profile.user_email =
      //   parser.emails?.length > 0 ? parser.emails[0] : "";
      this.profile.user_job_country_of_residence = parser.location?.country;
      this.profile.user_job_introduction = parser.summary;
      // let length = parser.workExperience.length - 1;
      // this.profile.user_job_current_org =
      //   parser.workExperience[length]?.organization;
      //this.profile.skills = parser.skills;
      this.profile.user_job_cv_path = parser.cv.user_job_cv_path;
      this.profile.user_job_cv_name = this.profile.user_job_cv_path ? this.profile.user_job_cv_path.split("/")[5].split("_")[1] : "";

      //this.job_title = this.profile.sections[0].text.split("\n")[1];

      const payload = { applicant_id: this.user_id };
      const ap = await ApplicantProfileService.getApplicantProfile(payload);
      let appProfile = ap.data.data;
      const postData = new FormData();
      // const par = getParser.data.data.parser;

      // const exp_id = appProfile.user_experience.map(({ experiance_id }) => {
      //   return experiance_id;
      // });
      // const edu_id = appProfile.user_education.map(({ education_id }) => {
      //   return education_id;
      // });

      let exp = parser.workExperience.map((m) => {
        return {
          description: "",
          designation: m.jobTitle,
          org_name: m?.organization,
          dateRange: {
            startDate: m.dates && m.dates.startDate ? moment(m.dates.startDate).format("YYYY-MM-DD") : null,
            endDate: m.dates && m.dates.endDate ? moment(m.dates.endDate).format("YYYY-MM-DD") : null,
          },
          description: m.jobDescription,
        };
      });
      // exp_id.forEach((id, i) => {
      //   if (exp.length > i) {
      //     exp[i].experiance_id = id;
      //   }
      // });
      // exp_id = exp_id.filter(f => {f == exp})
      let edu = parser.education.map((m) => {
        return {
          degree: m.accreditation && m.accreditation.education ? m.accreditation.education : null,
          university_name: m?.organization,
          dateRange: {
            startDate: null,
            endDate: m.dates && m.dates.completionDate ? moment(m.dates.completionDate).format("YYYY-MM-DD") : null,
          },
        };
      });
      // edu_id.forEach((id, i) => {
      //   if (edu.length > i) {
      //     edu[i].education_id = id;
      //   }
      // });
      const pd = {
        country: parser.location ? parser.location.country : null,
        // nationality: parser.location?.country,
      };
      let cj = {
        introduction: parser.summary,
        skills: parser.skills.map((m) => {
          return {
            label: m,
            skill_id: null,
          };
        }),
        delSkills: [],
      };
      if (cj.skills.length > 10) {
        cj.skills.length = 10;
      }

      let social = {
        linkedin: null,
        instagram: null,
        facebook: null,
        twitter: null,
      };

      if (parser.websites && parser.websites.length > 0) {
        parser.websites.map((x) => {
          if (x.includes("linkedin")) {
            social.linkedin = x;
          } else if (x.includes("instagram")) {
            social.instagram = x;
          } else if (x.includes("facebook")) {
            social.facebook = x;
          } else if (x.includes("twitter")) {
            social.twitter = x;
          }
        });
      }

      console.log("edu, exp, social :>> ", edu, exp, social);

      // if (
      //   appProfile.user_experience.length <= 1 ||
      //   appProfile.user_education.length <= 1
      // ) {
      postData.append("experiance", JSON.stringify(exp));
      // postData.append("personalDetails", JSON.stringify(pd));
      postData.append("education", JSON.stringify(edu));
      postData.append("currentJob", JSON.stringify(cj));
      postData.append("socialLink", JSON.stringify(social));

      const ApplicantProfile = await ApplicantProfileService.updateApplicantProfile(postData, this.user_id);
      if (ApplicantProfile.data.status) {
        this.getProfile();
      }
      // }
    },
    async getResumeParserData() {
      console.log("hitting this.....");
      const user_id = this.$cookies.get("user_id");
      try {
        const getParser = await ApplicantProfileService.getUploadCv({
          user_id,
        });
        console.log("getParser.data.data :>> ", getParser.data.data);
        let parser = getParser.data.data.parser.data;
        console.log("parser :>> ", parser);
        this.profile.user_username = parser.name?.raw;
        this.profile.user_job_title = parser.sections[0] && parser.sections[0].text ? parser.sections[0]?.text.split("\n")[1] : null;
        this.profile.user_email = parser.emails?.length > 0 ? parser.emails[0] : "";
        this.profile.user_job_country_of_residence = parser.location?.country;
        this.profile.user_job_introduction = parser.summary;
        let length = parser.workExperience.length - 1;
        this.profile.user_job_current_org = parser.workExperience[length]?.organization;
        //this.profile.skills = parser.skills;
        this.cv = getParser.data.data.cv;
        //this.job_title = this.profile.sections[0].text.split("\n")[1];

        const payload = { applicant_id: this.user_id };
        const ap = await ApplicantProfileService.getApplicantProfile(payload);
        let appProfile = ap.data.data;
        const postData = new FormData();
        // const par = getParser.data.data.parser;

        const exp_id = appProfile.user_experience.map(({ experiance_id }) => {
          return experiance_id;
        });
        const edu_id = appProfile.user_education.map(({ education_id }) => {
          return education_id;
        });

        let exp = parser.workExperience.map((m) => {
          return {
            description: "",
            designation: m.jobTitle,
            org_name: m?.organization,
            dateRange: {
              startDate: m.dates.startDate ? moment(m.dates.startDate).format("YYYY-MM-DD") : "",
              endDate: m.dates.endDate ? moment(m.dates.endDate).format("YYYY-MM-DD") : "",
            },
          };
        });
        exp_id.forEach((id, i) => {
          if (exp.length > i) {
            exp[i].experiance_id = id;
          }
        });
        let edu = parser.education.map((m) => {
          return {
            degree: m.accreditation.education,
            university_name: m?.organization,
            dateRange: {
              startDate: "",
              endDate: new Date(m.dates.completionDate),
            },
          };
        });
        edu_id.forEach((id, i) => {
          if (edu.length > i) {
            edu[i].education_id = id;
          }
        });
        const pd = {
          country: parser.location?.country,
          nationality: parser.location?.country,
        };
        const cj = {
          introduction: parser.summary,
        };

        if (appProfile.user_experience.length <= 1 && appProfile.user_education.length <= 1) {
          postData.append("experiance", JSON.stringify(exp));
          postData.append("personalDetails", JSON.stringify(pd));
          postData.append("education", JSON.stringify(edu));
          postData.append("currentJob", JSON.stringify(cj));

          const ApplicantProfile = await ApplicantProfileService.updateApplicantProfile(postData, this.user_id);
          if (ApplicantProfile.data.status) {
            this.getProfile();
          }
          this.hideModal();
        } else {
          this.getProfile();
          this.hideModal();
        }
      } catch (error) {

        this.hideModal();
      }
    },
    onClickRecordVideo() {
      // this.getUploadUrl();
      this.showVideoRecorder();
    },
    getProfile() {
      let loader = this.$loading.show();
      let steps = 0;
      let applicant_id = this.$cookies.get("user_id");
      const payload = { applicant_id: applicant_id };
      ApplicantProfileService.getApplicantProfile(payload)
        .then((data) => {
          this.parser_id = data.data.data.user_data[0].parser_id;

          this.profile = {
            ...data.data.data.user_data[0],
            ...data.data.data.user_cv[0],
          };
          this.profile.skills = data.data.data.user_data[0].skills.map((m) => {
            return m.skill_name;
          });
          if (this.profile.user_job_preferred)
            // this.$cookies.set("job_title", this.profile.user_job_preferred);
            this.$store.commit("ON_SET_JOB_TITLE", {
              job_title: this.profile.user_job_preferred,
            });

          console.log("this.$store.getters.getJobTitle :>> ", this.$store.getters.getJobTitle);
          // this.cv = this.profile.cv;
          // this.cvName = this.profile.cv.split("/")[5];

          this.exp = data.data.data.user_experience;
          this.education = data.data.data.user_education;

          this.skills = this.profile.skills.map(({ skill_name }) => {
            return skill_name;
          });
          // console.log(
          //   'this.profile.user_job_cv_path.split("/")[5] :>> ',
          //   this.profile.user_job_cv_path.split("/")[5]
          // );
          // this.cvName = this.profile.user_job_cv_path
          //   ? this.profile.user_job_cv_path.split("/")[5].split("_")[1]
          //   : "";
          if (this.profile.user_job_cv_path) {
            steps = 20;
          }
          if (this.profile.skills) {
            steps = steps + 10;
          }
          if (this.profile.user_job_profile_image && !this.uploadImg) {
            steps = steps + 20;
          }
          if (this.exp.length > 0) {
            steps = steps + 20;
          }
          if (this.education.length > 0) {
            steps = steps + 20;
          }
          if (this.profile.instagram || this.profile.linked_in || this.profile.facebook || this.profile.twitter) {
            steps = steps + 10;
          }
          this.completedSteps = steps;
          this.$cookies.set("residence", this.profile.user_job_country_of_residence);
          this.$cookies.set("country_id", this.profile.country_id);
          this.$cookies.set("city_id", this.profile.city_id);
          const p = {
            location: {
              country_name: this.profile.country_name,
              country_id: this.profile.country_id,
            },
          };

          const c = {
            city: {
              city_name: this.profile.city_name,
              city_id: this.profile.city_id,
            },
          };
          console.log("p :>> ", p);
          this.$store.commit("ON_SET_COUNTRY", p);
          this.$store.commit("ON_SET_CITY", c);
          const pay = { image: this.profile.user_job_profile_image };
          this.$store.dispatch("onSetProfileImage", pay);
          const country = { country: this.profile.country_name };
          this.$store.commit("ON_SET_COUNTRY", country);
          localStorage.setItem("image", this.profile.user_job_profile_image);

          const location = {
            location: this.profile.user_job_country_of_residence,
          };
          this.$store.dispatch("OnSetLocation", location);
          //console.log("prop :>> ", this.profile[prop]);
          // if (
          //   this.profile.user_job_cv_path &&
          //   !this.profile.user_job_introduction
          // ) {
          //   console.log("hitting Getresume parser-----");
          //   this.getResumeParserData();
          // }
          loader.hide();
        })
        .catch((err) => {

          loader.hide();
        });
    },
    onClick(e) {
      this.selectedVideo = e;
      this.playVideo = false;
      this.recordingOn = false;
      this.video_title = e.user_job_video_title;
      this.video_description = e.user_job_video_description;
    },
    onUploadImage(e) {
      let image = e.target.files[0];
      let loader = this.$loading.show();

      let form = new FormData();
      form.append("image", image);
      ApplicantProfileService.uploadProfileImage(form)
        .then((res) => {

          const pay = { image: res.data.data.path };
          this.$store.dispatch("onSetProfileImage", pay);
          if (this.$store.getters.getProfileImage) {
            this.completedSteps = this.completedSteps + 20;
          }
          this.uploadImg = true;
          loader.hide();
        })
        .catch((err) => {
          loader.hide();
        });
    },
    onDeleteImage() {
      let loader = this.$loading.show();
      ApplicantProfileService.deleteProfileImage()
        .then((res) => {

          const pay = { image: res.data.data.path };
          this.$store.dispatch("onSetProfileImage", pay);
          if (!this.$store.getters.getProfileImage) {
            this.completedSteps = this.completedSteps - 20;
          }
          this.uploadImg = false;
          loader.hide();
        })
        .catch((err) => {

          loader.hide();
        });
    },

    onFileChange(e) {
      let f = e.target.files[0];
      if (f.type == "video/mp4") {
        // this.getUploadUrl();
        this.videoInput = f;
        this.fileSelected = "File Selected";

        //preview videoInput

        var file = e.target.files[0];
        var fileReader = new FileReader();
        var x = this;
        fileReader.onload = function () {
          var blob = new Blob([fileReader.result], { type: file.type });
          var url = URL.createObjectURL(blob);
          var video = x.$refs["video"];
          console.log("video :>> ", video);
          var timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
              video.pause();
            }
          };
          video.addEventListener("loadeddata", function () {
            if (snapImage()) {
              video.removeEventListener("timeupdate", timeupdate);
            }
          });
          var snapImage = function () {
            var canvas = x.$refs["canvas"];
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);
            var image = canvas.toDataURL();
            x.previewImage2 = canvas.toDataURL();
            console.log(" x.previewImage2 :>> ", x.previewImage2);
            //this.previewImage = x.previewImage2;
            var success = image.length > 100000;
            if (success) {
              // var img = x.$refs["img"];
              // img.src = image;
              // x.$refs["video-thumbnail"].appendChild(img);
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener("timeupdate", timeupdate);
          video.preload = "metadata";
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        };
        fileReader.readAsArrayBuffer(file);

        this.$toasted.show("File Selected", { duration: 6000, icon: "check" });
      } else {
        this.$toasted.show("please select mp4 formatted video", {
          duration: 6000,
          icon: "clear",
        });
      }
    },
    getSubs() {
      const user_id = this.$cookies.get("user_id");
      ApplicantProfileService.getUserSubscription(user_id).then((res) => {
        this.subs = res.data.data.length ? res.data.data[0] : null;

      });
    },
    onDeleteVideo() {
      if (this.videos.length > 0 && this.selectedVideo) {
        const video_id = this.selectedVideo.user_job_video_id;
        this.$bvModal
          .msgBoxConfirm("Do You Want to delete this video", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              ApplicantProfileService.deleteVideo({ video_id }).then((res) => {
                this.$toasted.show("Video Delete Successfully!", {
                  duration: 6000,
                  icon: "check",
                });
                this.getAllVideos();
              });
            } else {
              return;
            }
          });
      } else {
        this.$toasted.show("Select Video", { duration: 6000, icon: "clear" });
      }
    },
    onUpload() {
      this.$refs.videoInput.validate().then(async (result) => {
        if (result) {
          try {
            if (this.videoInput && this.videoInput.type == "video/mp4") {
              if (this.videos.length <= this.subs.subs_pkg_no_of_videos) {
                if (this.videoInput.size <= 104857600) {
                  this.loading = true;
                  this.$bvModal.show("myModel");

                  this.showRadial = true;
                  const formData = new FormData();
                  formData.append("file", this.videoInput);
                  // const uploadResult = await fetch(this.url, {
                  //   method: "POST",
                  //   body: formData,
                  // });
                  // console.log("uploadResult :>> ", uploadResult);
                  // this.videoUrl = uploadResult.url;
                  axios
                    .post(this.url, formData, {
                      onUploadProgress: function (progressEvent) {
                        this.videoUploaded = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
                      }.bind(this),
                    })
                    .then((uploadResult) => {
                      this.videoUrl = uploadResult.config.url;
                      this.onSubmit();
                    })
                    .catch((error) => {

                      this.videoUploaded = 0;
                    });
                } else {
                  this.loading = false;
                  this.$bvModal.hide("myModel");
                  this.$toasted.show("video size should be less than 100 mb", {
                    duration: 6000,
                    icon: "clear",
                  });
                }
              } else {
                this.$toasted.show("You have uploaded videos upto max limit", {
                  duration: 6000,
                  icon: "clear",
                });
              }
            } else {
              this.$toasted.show("Upload video with mp4 format!", {
                duration: 6000,
                icon: "clear",
              });
              return;
            }
          } catch (error) {

          }
        } else {
          this.$toasted.show("Enter required fields", { duration: 6000 });
        }
      });
    },
    onSubmit() {
      const payload = {
        role_id: this.$cookies.get("role_id"),
        user_id: this.$cookies.get("user_id"),
        video_status: this.video_status,
        video_title: this.video_title,
        video_id: this.selectedVideo ? this.selectedVideo.user_job_video_id : null,
        video_description: this.video_description,
        video_path: this.videoUrl.split("/")[3].split("-")[0],
        video_length: this.video_length,
      };

      if (payload.video_path) {
        ApplicantProfileService.uploadVideo(payload)
          .then((res) => {
            this.videoUrl = null;
            // this.$forceUpdate();
            this.getAllVideos();
            this.loading = false;
            this.fileSelected = "";
            this.$bvModal.hide("myModel");
            this.$toasted.show("Your video uploaded succefully!", {
              position: "top-right",
              duration: 6000,
              icon: "check",
            });
            this.recordingOn = false;
            this.video_status = "update";
            this.player.record().stopDevice();
            this.player.record().reset();

            this.isStartRecording = false;
            this.submitText = "Submit";
            this.isSaveDisabled = true;
          })
          .catch((err) => {
            this.$bvModal.hide("myModel");

          });
      } else {
        this.$toasted.show("Video not recorded!", {
          position: "top-right",
          duration: 6000,
        });
        this.loading = false;
        this.$bvModal.hide("myModel");
        return;
      }
    },
    onSelectVideo(i) {
      this.selectedVideo = this.videos[i];
    },
    async getAllVideos() {
      // let loader = this.$loading.show();
      const payload = { user_id: this.$cookies.get("user_id") };
      try {
        const getVideos = await ApplicantProfileService.GetUploadedVideos(payload);

        this.videos = getVideos.data.data;
        let v = this.videos.some((j) => j.archived === "Y");
        this.archived = v;
        if (this.videos.length > 0) {
          this.video_status = "update";
          this.selectedVideo = this.videos[0];
          this.video_title = this.selectedVideo.user_job_video_title;
          this.video_description = this.selectedVideo.user_job_video_description;
          //loader.hide();
          if (this.$refs.slick) {
            this.$refs.slick.destroy();
            this.$nextTick(() => {
              this.$refs.slick.create();
              this.$refs.slick.goTo(0, true);
            });
          }
        } else {
          console.log("hitting");
          this.video_status = "add";
          //loader.hide();
        }
      } catch (error) {

        //   loader.hide();
      }
    },
    /////////////////////VideoJs config////////////////////////
    startRecording() {
      this.isStartRecording = true;
      this.player.record().getDevice();
    },
    finishRecording() {
      this.player.record().stopDevice();
      console.log("this.player :>> ", this.player);

      //  this.player.on('loadedmetadata', function(f) {

      //    });

      //const currentTime = this.player.currentTime();
      //  const timeoutId = this.player.id();
      //  console.log('timeoutId :>> ', timeoutId);
      //  let time = window.clearTimout;
      //  console.log('time :>> ', time);
      //  this.player.clearTimeout(timeoutId);
      this.isRetakeDisabled = false;
    },
    submitVideo() {
      this.$refs.videoInput.validate().then((result) => {
        if (result) {
          this.isSaveDisabled = true;
          this.isRetakeDisabled = true;
          var data = this.player.recordedData;
          var formData = new FormData();
          formData.append("file", data, data.name);
          this.submitText = "Uploading " + data.name;
          console.log("uploading recording:", data.name);
          this.player.record().stopDevice();
          this.$bvModal.show("myModel");
          axios
            .post(this.url, formData, {
              onUploadProgress: function (progressEvent) {
                this.videoUploaded = parseInt(Math.round(progressEvent.loaded / progressEvent.total) * 100);
              }.bind(this),
            })
            .then((uploadResult) => {
              console.log("uploadResult :>> ", uploadResult);
              this.videoUrl = uploadResult.config.url;
              this.onSubmit();
            })
            .catch((error) => {

              this.videoUploaded = 0;
            });
        } else {
          this.$toasted.show("Please enter the title and description of the video", {
            duration: 6000,
            icon: "clear",
          });
          return;
        }
      });
      // fetch(this.uploadUrl, {
      //   method: "POST",
      //   body: formData,
      //   headers: {
      //     "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
      //   },
      // })
      //   .then((success) => {
      //     console.log("recording upload complete.");
      //     this.submitText = "Upload Complete";
      //   })
      //   .catch((error) => {
      //     console.error("an upload error occurred!");
      //     this.submitText = "Upload Failed";
      //   });
    },
    retakeVideo() {
      this.isSaveDisabled = true;
      this.isRetakeDisabled = true;
      this.retake += 1;
      this.player.record().start();
    },
  },
  // beforeDestroy() {
  //   if (this.player) {
  //     this.player.dispose();
  //   }
  // },
};
</script>

<style>
.reupload-btn {
  /* font-family: mp-bold; */
  font-size: 12px !important;
  letter-spacing: 1.6px;
  background: #6244e5;
  color: white;
  padding: 10px;
  margin-bottom: 0;
}

.btn-video-upload {
  font-size: 12px !important;
  letter-spacing: 1.6px;
  background: #6244e5;
  color: white;
  padding: 10px;
  margin-bottom: 0;
}

.app-profile-overlay {
  background: rgba(0, 0, 0, 0.58);
  border-radius: 08px;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50px;
  height: 160px;
  width: 160px;
  align-items: center;
}</style>
